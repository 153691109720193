import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Styled404Page = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage = () => {
  return (
    <Styled404Page>
      <Helmet>
        <title>404 - Mars Institute</title>
        <meta name="prerender-status-code" content="404"></meta>
      </Helmet>
      <h2>Page not found</h2>
      <p>Sorry, this page could not be found.</p>
      <p>
        <Link to="/">Return home</Link>
      </p>
    </Styled404Page>
  );
};

export default NotFoundPage;
