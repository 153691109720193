import { createClient } from '@sanity/client';
import urlBuilder from '@sanity/image-url';

import {
  Project,
  ImageObject,
  ExternalContent,
  Person,
  StudentInfoPage,
  NewsArticle,
  InformationContent,
} from '../types';

export const sanityClient = createClient({
  projectId: '6t9vwr5d',
  dataset: 'production',
  apiVersion: '2021-10-21',
  useCdn: true, // set to `true` to fetch from edge cache
});

export const getImageUrl = (image: ImageObject) => {
  return urlBuilder(sanityClient).image(image).url();
};

export const GetInformationContent = async (): Promise<InformationContent | undefined> => {
  const informationContent = (await sanityClient.fetch(
    `*[_type == "informationContent"][0]`
  )) as InformationContent;
  return informationContent;
};

export const GetNewsArticles = async (count?: number): Promise<Array<NewsArticle> | undefined> => {
  const newsArticles = (await sanityClient.fetch(
    `*[_type == "newsArticle"] | order(publishedAt desc)${count ? `[0..${count - 1}]` : ''}`
  )) as Array<NewsArticle>;
  return newsArticles;
};

export const GetNewsArticle = async (slug: string): Promise<NewsArticle | undefined> => {
  const newsArticle = (await sanityClient.fetch(
    `*[_type == "newsArticle" && slug.current == "${slug}"][0]`
  )) as NewsArticle;
  return newsArticle;
};

export const GetProjects = async (): Promise<Array<Project> | undefined> => {
  const projects = (await sanityClient.fetch(
    `*[_type == "project"] | order(updatedAt desc)`
  )) as Array<Project>;
  return projects;
};

export const GetProject = async (slug: string): Promise<Project | undefined> => {
  const project = (await sanityClient.fetch(
    `*[_type == "project" && slug.current == "${slug}"][0]`
  )) as Project;
  return project;
};

export const GetStudentInfoPages = async (): Promise<Array<StudentInfoPage> | undefined> => {
  const studentInfoPage = (await sanityClient.fetch(
    `*[_type == "studentInfoPage"] | order(_updatedAt desc)`
  )) as Array<StudentInfoPage>;
  return studentInfoPage;
};

export const GetStudentInfoPage = async (slug: string): Promise<StudentInfoPage | undefined> => {
  const studentInfoPage = (await sanityClient.fetch(
    `*[_type == "studentInfoPage" && slug.current == "${slug}"][0]`
  )) as StudentInfoPage;
  return studentInfoPage;
};

export const GetPersons = async (): Promise<Array<Person> | undefined> => {
  const persons = (await sanityClient.fetch(
    `*[_type == "person"] | order(_updatedAt desc)`
  )) as Array<Person>;
  return persons;
};

export const GetPerson = async (slug: string): Promise<Person | undefined> => {
  const person = (await sanityClient.fetch(
    `*[_type == "person" && slug.current == "${slug}"][0]`
  )) as Person;
  return person;
};

export const GetExternalContent = async (): Promise<Array<ExternalContent> | undefined> => {
  const externalContent = (await sanityClient.fetch(
    `*[_type == "externalContent"]`
  )) as Array<ExternalContent>;
  return externalContent;
};
