import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import SanityImageComponent from '../components/SanityImageComponent';
import SanityPortableText from '../components/SanityPortableText';
import { CenteredContentContainer } from '../components/Containers';
import { redirectSlugs } from '../components/HiddenRedirectLinks';
import Loader from '../components/Loader';

import NotFoundPage from './NotFoundPage';

import { Project } from '../types';

import { GetProject, getImageUrl } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

import Colors from '../colors';

const StyledBodyAndFactsContent = styled('div')`
  aside {
    margin-top: 32px;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;

    section {
      width: 67%;
      padding-right: 16px;
    }

    aside {
      width: 33%;
      height: fit-content;
      padding-left: 16px;
      border-left: 1px solid ${Colors.darkTransparent};

      figure {
        margin: 0;
      }
    }
  }
`;

const StyledImageCarousel = styled(Carousel)`
  margin-top: 32px;

  .legend {
    display: none;
  }

  @media (min-width: 768px) {
    .legend {
      display: block;
    }
  }
`;

const SyledMoreInfoContent = styled('div')`
  margin: 1rem 0;
  opacity: 0.75;

  p {
    margin-block: 2rem;
    font-size: 1rem;
  }
`;

const ProjectPage = () => {
  const { slug } = useParams();
  const [project, setProject] = useState<Project | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      GetProject(redirectSlugs[slug] ?? slug).then((p) => {
        setProject(p);
        setLoading(false);
      });
    }
  }, [slug]);

  return project ? (
    <CenteredContentContainer>
      <Helmet>
        <title>{`${project.title} - Mars Institute`}</title>
        <meta property="og:title" content={`${project.title} - Mars Institute`} />
        <meta
          property="og:url"
          content={`https://marsinstitute.no/projects/${project.slug.current}`}
        />
        <meta name="description" content={project.summary} />
        <meta property="og:description" content={project.summary} />
        <meta property="og:image" content={project.logo ? getImageUrl(project.logo) : logo} />
        <meta name="twitter:card" content={'summary'} />
      </Helmet>

      <article>
        <h1>{project.title}</h1>

        {project.mainImage && (
          <SanityImageComponent
            value={project.mainImage}
            isInline={false}
            className="full-width-image"
          />
        )}

        <p>{project.summary}</p>

        <StyledBodyAndFactsContent>
          <section>
            <SanityPortableText content={project.body} />
          </section>
          <aside>
            <SanityPortableText content={project.sidePanel} />
          </aside>
        </StyledBodyAndFactsContent>

        {project.images?.length > 0 && (
          <section>
            <StyledImageCarousel>
              {project.images.map((img) => {
                let imgSrc = getImageUrl(img);
                return (
                  <div key={imgSrc}>
                    <img src={imgSrc} alt={img.alt} />
                    <p className="legend">{img.figureText}</p>
                  </div>
                );
              })}
            </StyledImageCarousel>
          </section>
        )}

        <SyledMoreInfoContent>
          <section>
            <SanityPortableText content={project.moreInfo} />
          </section>
        </SyledMoreInfoContent>
      </article>
    </CenteredContentContainer>
  ) : loading ? (
    <Loader />
  ) : (
    <NotFoundPage />
  );
};

export default ProjectPage;
