import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { FullWidthContainer } from '../components/Containers';
import Loader from '../components/Loader';

import { useCommonInformationContext } from '../contexts/commonInformationContext';

import logo from '../assets/img/mi_circle.webp';
import { getImageUrl } from '../utils/sanity';

const BannerImage = styled('div')<{ imageSrc: string }>`
  background: ${({ imageSrc }) => `url(${imageSrc}) 50% center / cover no-repeat`};
  height: 200px;
  width: calc(100% + 40px);
  margin: -20px -20px 0;

  @media (min-width: 4000px) {
    border-radius: 0 0 100px 100px;
  }
`;

const StyledAboutText = styled('section')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;

  @media (min-width: 768px) {
    width: 85%;
  }

  @media (min-width: 1024px) {
    width: 75%;
  }

  @media (min-width: 1600px) {
    width: 55%;
  }

  p {
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
  }
`;

const HomePage = () => {
  const informationContext = useCommonInformationContext();

  return (
    <>
      <Helmet>
        <title>Mars Institute</title>
        <meta property="og:title" content="Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no" />
        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
          conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
          conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <FullWidthContainer>
        {informationContext.informationContent?.bannerImage && (
          <BannerImage imageSrc={getImageUrl(informationContext.informationContent.bannerImage)} />
        )}
      </FullWidthContainer>
      {!informationContext.informationContent ? (
        <Loader />
      ) : (
        <StyledAboutText>
          <p>{informationContext.informationContent.aboutMiShort}</p>
        </StyledAboutText>
      )}
    </>
  );
};

export default HomePage;
