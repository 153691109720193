import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import SanityImageComponent from '../components/SanityImageComponent';
import SanityPortableText from '../components/SanityPortableText';
import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';

import NotFoundPage from './NotFoundPage';

import { Person } from '../types';

import Colors from '../colors';

import { getImageUrl, GetPerson } from '../utils/sanity';

const StyledPersonInfo = styled('article')`
  display: flex;
  flex-direction: column;

  figure {
    align-self: center;
    max-width: 75%;
  }

  .person-bio {
    margin-top: -1em;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    figure {
      align-self: flex-start;
      width: 35%;
      margin: 0;
      padding-top: 1rem;
      padding-left: 16px;
    }

    .person-bio {
      width: 65%;
      margin-top: 0;
      padding-right: 16px;
      border-right: 1px solid ${Colors.darkTransparent};
    }
  }
`;

const PersonPage = () => {
  const { slug } = useParams();
  const [person, setPerson] = useState<Person | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      GetPerson(slug).then((person) => {
        setPerson(person);
        setLoading(false);
      });
    }
  }, [slug]);

  return person ? (
    <CenteredContentContainer>
      <Helmet>
        <title>{`${person.name} - Mars Institute`}</title>
        <meta property="og:title" content={`${person.name} - Mars Institute`} />
        <meta property="og:url" content={`https://marsinstitute.no/team/${person.slug.current}`} />
        <meta property="og:image" content={person.image && getImageUrl(person.image)} />
        <meta name="twitter:card" content={'summary'} />
      </Helmet>

      <h1>{person.name}</h1>

      <StyledPersonInfo>
        <div className="person-bio">
          <SanityPortableText content={person.bio} />
        </div>
        {person.image && <SanityImageComponent value={person.image} isInline={false} />}
      </StyledPersonInfo>
    </CenteredContentContainer>
  ) : loading ? (
    <Loader />
  ) : (
    <NotFoundPage />
  );
};

export default PersonPage;
