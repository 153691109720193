import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import SanityPortableText from '../components/SanityPortableText';
import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';

import NotFoundPage from './NotFoundPage';

import { StudentInfoPage } from '../types';

import { getImageUrl, GetStudentInfoPage } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const StudentInformationPage = () => {
  const { slug } = useParams();
  const [studentInfoPage, setStudentInfoPage] = useState<StudentInfoPage | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      GetStudentInfoPage(slug).then((infoPage) => {
        setStudentInfoPage(infoPage);
        setLoading(false);
      });
    }
  }, [slug]);

  return studentInfoPage ? (
    <CenteredContentContainer>
      <Helmet>
        <title>{`${studentInfoPage.title} - Mars Institute`}</title>
        <meta property="og:title" content={`${studentInfoPage.title} - Mars Institute`} />
        <meta
          property="og:url"
          content={`https://marsinstitute.no/news/${studentInfoPage.slug.current}`}
        />
        <meta name="description" content={studentInfoPage.summary} />
        <meta property="og:description" content={studentInfoPage.summary} />
        <meta
          property="og:image"
          content={studentInfoPage.mainImage ? getImageUrl(studentInfoPage.mainImage) : logo}
        />
        <meta name="twitter:card" content={'summary'} />
      </Helmet>

      <article>
        <h1>{studentInfoPage.title}</h1>
        <SanityPortableText content={studentInfoPage.body} />
      </article>
    </CenteredContentContainer>
  ) : loading ? (
    <Loader />
  ) : (
    <NotFoundPage />
  );
};

export default StudentInformationPage;
