import styled from 'styled-components';

import ButtonLink from './ButtonLink';

const StyledPagePreviewCard = styled('article')`
  display: flex;
  flex-flow: column;
  border-radius: 4px;
  padding-bottom: 32px;
`;

const StyledPagePreviewSummary = styled('p')`
  margin-top: 0;
`;

interface Props {
  page: {
    title: string;
    summary: string;
  };
  pagePath: string;
}

const PagePreviewCard = ({ page, pagePath }: Props) => {
  return (
    <StyledPagePreviewCard>
      <h3>{page.title}</h3>
      <StyledPagePreviewSummary>{page.summary}</StyledPagePreviewSummary>
      <ButtonLink href={pagePath} text="Read more" />
    </StyledPagePreviewCard>
  );
};

export default PagePreviewCard;
