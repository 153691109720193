import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';

import { CenteredContentContainer } from './Containers';
import SanityPortableText from './SanityPortableText';

import { useCommonInformationContext } from '../contexts/commonInformationContext';

import Colors from '../colors';

import logo from '../assets/img/mi_circle.webp';

const StyledFormContainer = styled('div')<{ submitSuccess?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    display: block;
    width: 100%;

    @media (min-width: 768px) {
      width: 70%;
    }

    * {
      font-family: 'Titillium Web', sans-serif;
    }

    label {
      font-size: 1.2rem;
    }

    textarea {
      height: 150px;
    }

    input[type='text'],
    input[type='email'] {
      margin-bottom: 32px;
      height: 32px;
    }

    input[type='text'],
    input[type='email'],
    textarea,
    label {
      width: 100%;
    }

    input[type='text'],
    input[type='email'],
    textarea {
      background: ${Colors.white};
      box-sizing: border-box;
      border: 1px solid ${Colors.dark};
      border-radius: 4px;
      font-size: 1.1rem;
      margin-bottom: 32px;
      padding: 4px;
    }

    input[type='submit'] {
      font-size: 1rem;
      color: black;
      background: ${Colors.darkTransparent};
      border-radius: 4px;
      padding: 8px;
      width: 64px;
      border: none;
      cursor: pointer;

      :hover,
      :focus {
        color: ${Colors.orange};
      }
    }

    #form-result-text {
      width: 100%;
      margin-top: 32px;
      color: ${({ submitSuccess }) => (submitSuccess ? 'green' : 'red')};
    }
  }
`;

const ContactForm = () => {
  const form: React.MutableRefObject<HTMLFormElement | null> = useRef(null);
  const [formSubmitResult, setFormSubmitResult] = useState<string | undefined>(undefined);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState<boolean | undefined>(undefined);

  const informationContext = useCommonInformationContext();

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm('service_nz2u8qe', 'template_a7artbn', form?.current ?? '', 'rdhaCd6rB8YIrrwG7')
      .then(
        () => {
          form?.current?.reset();
          setFormSubmitResult('We have received your e-mail and will respond shortly.');
          setFormSubmitSuccess(true);
        },
        () => {
          setFormSubmitResult('An error occured. Please try again later.');
          setFormSubmitSuccess(false);
        }
      );
  };

  return (
    <CenteredContentContainer>
      <Helmet>
        <title>Contact us - Mars Institute</title>
        <meta property="og:title" content="Contact us - Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no/contact" />
        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <section>
        <h1>Contact us</h1>
        {informationContext.informationContent?.contactPageText && (
          <>
            <SanityPortableText content={informationContext.informationContent.contactPageText} />
            <br />
          </>
        )}
        <StyledFormContainer submitSuccess={formSubmitSuccess}>
          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor="user_name">Name </label>
            <input type="text" name="user_name" id="user_name" required />
            <label htmlFor="user_email">E-mail </label>
            <input type="email" name="user_email" id="user_email" required />
            <label htmlFor="message">Message </label>
            <textarea name="message" id="message" required />
            <input type="submit" value="Send" />
            {formSubmitResult && <p id="form-result-text">{formSubmitResult}</p>}
          </form>
        </StyledFormContainer>
      </section>
    </CenteredContentContainer>
  );
};

export default ContactForm;
