import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';
import PagePreviewCard from '../components/PagePreviewCard';
import SanityPortableText from '../components/SanityPortableText';

import { useCommonInformationContext } from '../contexts/commonInformationContext';

import { Project } from '../types';

import { GetProjects } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const ProjectsPage = () => {
  const [projects, setProjects] = useState<Array<Project> | undefined>();
  const informationContext = useCommonInformationContext();

  useEffect(() => {
    GetProjects().then((projs) => setProjects(projs));
  }, []);

  return (
    <CenteredContentContainer>
      <Helmet>
        <title>Projects - Mars Institute</title>
        <meta property="og:title" content="Projects - Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no/projects" />

        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <article>
        <h1>Projects</h1>
        {informationContext.informationContent?.projectsPageText && (
          <>
            <SanityPortableText content={informationContext.informationContent.projectsPageText} />
            <br />
          </>
        )}
        {projects ? (
          projects.map((p) => (
            <PagePreviewCard page={p} pagePath={`/projects/${p.slug.current}`} key={p._id} />
          ))
        ) : (
          <Loader />
        )}
      </article>
    </CenteredContentContainer>
  );
};

export default ProjectsPage;
