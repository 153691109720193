import styled from 'styled-components';

import logo from '../assets/img/mi_circle.webp';

const StyledLoader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;

  .App-logo {
    max-height: 96px;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 5s linear;
    }
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => {
  return (
    <StyledLoader>
      <img src={logo} className="App-logo" alt="loading ..." />
      <em>Loading ...</em>
    </StyledLoader>
  );
};

export default Loader;
