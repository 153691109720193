import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '../components/Footer';
import Header from '../components/Header';
import HiddenRedirectLinks from '../components/HiddenRedirectLinks';

const StyledMain = styled('main')`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-height: calc(100vh - 233px);
  padding: 20px 20px 40px;
`;

const BasePage = () => {
  return (
    <>
      <Header />
      <StyledMain>
        <Outlet />
      </StyledMain>
      <Footer />
      <HiddenRedirectLinks />
    </>
  );
};

export default BasePage;
