import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { InformationContent } from '../types';
import { GetInformationContent } from '../utils/sanity';

interface CommonInformationContextInterface {
  informationContent: InformationContent | undefined;
}

export const CommonInformationContext = createContext<CommonInformationContextInterface | null>(
  null
);

export const CommonInformationProvider = ({ children }: any) => {
  const [informationContent, setInformationContent] = useState<InformationContent>();

  useEffect(() => {
    GetInformationContent().then((info) => {
      setInformationContent(info);
    });
  }, []);

  const value = useMemo(
    () => ({
      informationContent,
    }),
    [informationContent]
  );

  return (
    <CommonInformationContext.Provider value={value}>{children}</CommonInformationContext.Provider>
  );
};

export function useCommonInformationContext() {
  const context = useContext(CommonInformationContext);
  if (!context) {
    throw new Error('useCommonInformationContext must be used within a CommonInformationProvider');
  }
  return context;
}
