import styled from 'styled-components';

export const FullWidthContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 4000px;
`;

export const HeaderContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
`;

export const CenteredContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;

  @media (min-width: 768px) {
    width: 85%;
  }

  @media (min-width: 1024px) {
    width: 75%;
  }

  @media (min-width: 1600px) {
    width: 55%;
  }

  figure {
    display: block;
    margin-block: 2rem;
    margin-inline: 0;

    @media (min-width: 768px) {
      margin-inline: 32px;
    }

    img {
      width: 100%;
    }
  }

  figure.full-width-image {
    @media (min-width: 768px) {
      margin-block: 0;
      margin-inline: 0;
    }
  }
`;
