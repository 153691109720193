import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderContentContainer } from './Containers';

import logo from '../assets/img/mi_logo.webp';
import Colors from '../colors';

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  height: 113px;
  padding: 16px 24px;
  background-color: ${Colors.dark};

  .mi-logo {
    img {
      position: relative;
      z-index: 99;
      height: 60px;

      @media (min-width: 600px) {
        height: 75px;
      }
    }
  }
`;

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NavMenu = styled.ul<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  background-color: ${Colors.dark};
  padding: 8px 0;
  margin-block: 0;
  position: absolute;
  top: ${({ open }) => (open ? '113px' : '0')};
  left: 0;
  width: 100%;
  z-index: 10;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0s ease-out;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
    position: static;
    transform: none;
    align-items: center;
  }
`;

const NavMenuItem = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: ${Colors.dark};

  a {
    font-size: 1.2rem;
    text-decoration: none;
    color: ${({ active }) => (active ? Colors.orange : Colors.light)};

    @media (min-width: 768px) {
      font-size: 1rem;
    }

    @media (min-width: 896px) {
      font-size: 1.2rem;
    }

    :hover {
      color: ${Colors.orange};
    }
  }

  @media (min-width: 768px) {
    padding: 0;
    margin: 0 1rem;
    display: block;
  }
`;

const Hamburger = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  z-index: 99;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    background-color: ${Colors.light};
    border-radius: 2px;
    transition: transform 0.1s ease-out;
  }

  &.open span:nth-of-type(1) {
    transform: translateY(7px) rotate(45deg);
  }

  &.open span:nth-of-type(2) {
    opacity: 0;
  }

  &.open span:nth-of-type(3) {
    transform: translateY(-7px) rotate(-45deg);
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeMenuItem, setActiveMenuItem] = useState<string | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    setActiveMenuItem(location.key !== 'default' ? location.pathname : undefined);
    setOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <StyledHeader>
      <HeaderContentContainer>
        <NavbarContainer>
          <Link to="/" className="mi-logo">
            <img src={logo} alt="Mars Institute logo" />
          </Link>
          <Hamburger className={open ? 'open' : ''} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </Hamburger>
          <NavMenu open={open}>
            <NavMenuItem active={activeMenuItem === '/about'}>
              <Link to="/about" onClick={() => setOpen(false)}>
                About
              </Link>
            </NavMenuItem>
            <NavMenuItem active={activeMenuItem === '/news'}>
              <Link to="/news" onClick={() => setOpen(false)}>
                News
              </Link>
            </NavMenuItem>
            <NavMenuItem active={activeMenuItem === '/projects'}>
              <Link to="/projects" onClick={() => setOpen(false)}>
                Projects
              </Link>
            </NavMenuItem>
            <NavMenuItem active={activeMenuItem === '/students'}>
              <Link to="/students" onClick={() => setOpen(false)}>
                Students
              </Link>
            </NavMenuItem>
            <NavMenuItem active={activeMenuItem === '/outreach'}>
              <Link to="/outreach" onClick={() => setOpen(false)}>
                Outreach
              </Link>
            </NavMenuItem>
            <NavMenuItem active={activeMenuItem === '/contact'}>
              <Link to="/contact" onClick={() => setOpen(false)}>
                Contact
              </Link>
            </NavMenuItem>
          </NavMenu>
        </NavbarContainer>
      </HeaderContentContainer>
    </StyledHeader>
  );
};

export default Header;
