import { Link } from 'react-router-dom';
import { Twitter, Linkedin, Facebook } from 'react-bootstrap-icons';
import styled from 'styled-components';

import Colors from '../colors';

const StyledFooter = styled('footer')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  height: 120px;
  padding: 16px;
  background-color: ${Colors.black};
  color: ${Colors.light};

  .SoMe-icons {
    padding-top: 8px;

    svg {
      padding: 0 8px;
      color: ${Colors.light};

      :hover {
        @media (min-width: 768px) {
          color: ${Colors.orange};
        }
      }
    }
  }

  p {
    margin: 0;
    font-size: 1rem;

    .C-symbol {
      position: relative;
      top: 4px;
      font-size: 1.4rem;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div className="SoMe-icons">
        <Link to="https://www.facebook.com/MarsInstituteOrg" target="_blank" rel="noindex nofollow">
          <Facebook title="Mars Institute Facebook" size={30} />
        </Link>
        <Link to="https://twitter.com/marsinstitute" target="_blank" rel="noindex nofollow">
          <Twitter title="Mars Institute Twitter" size={30} />
        </Link>
        <Link
          to="https://www.linkedin.com/company/mars-institute/"
          target="_blank"
          rel="noindex nofollow"
        >
          <Linkedin title="Mars Institute LinkedIn" size={30} />
        </Link>
      </div>
      <p>
        <span className="C-symbol">&#169;</span>
        {new Date().getFullYear()} Mars Institute
      </p>
    </StyledFooter>
  );
};

export default Footer;
