import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';
import TeamMemberPreviewCard from '../components/TeamMemberPreviewCard';
import SanityPortableText from '../components/SanityPortableText';

import { useCommonInformationContext } from '../contexts/commonInformationContext';

import { Person } from '../types';

import { GetPersons } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const StyledTeamMembersContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  figure {
    margin-top: 1rem !important;
  }
`;

const AboutPage = () => {
  const informationContext = useCommonInformationContext();
  const [teamMembers, setTeamMembers] = useState<Array<Person> | undefined>();

  useEffect(() => {
    // Get team members
    GetPersons().then((team) => {
      setTeamMembers(team);
    });
  }, []);

  return (
    <CenteredContentContainer>
      <Helmet>
        <title>Mars Institute</title>
        <meta property="og:title" content="About - Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no/about" />
        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <article>
        <h1>About</h1>
        {informationContext.informationContent?.aboutMiFull && (
          <>
            <SanityPortableText content={informationContext.informationContent.aboutMiFull} />
            <br />
          </>
        )}
        {teamMembers ? (
          <section>
            <h3>The team</h3>
            <StyledTeamMembersContainer>
              {teamMembers.map((teamMember) => (
                <TeamMemberPreviewCard
                  teamMember={teamMember}
                  pagePath={`/team/${teamMember.slug.current}`}
                  key={teamMember._id}
                />
              ))}
            </StyledTeamMembersContainer>
          </section>
        ) : (
          <Loader />
        )}
      </article>
    </CenteredContentContainer>
  );
};

export default AboutPage;
