import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { CenteredContentContainer } from '../components/Containers';
import PagePreviewCard from '../components/PagePreviewCard';
import Loader from '../components/Loader';

import { NewsArticle } from '../types';

import { GetNewsArticles } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const NewsPage = () => {
  const [newsArticles, setNewsArticles] = useState<Array<NewsArticle> | undefined>();

  useEffect(() => {
    GetNewsArticles().then((articles) => setNewsArticles(articles));
  }, []);

  return (
    <CenteredContentContainer>
      <Helmet>
        <title>News - Mars Institute</title>
        <meta property="og:title" content="News - Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no/news" />
        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <article>
        <h1>News</h1>
        {newsArticles ? (
          newsArticles.map((newsArticle) => (
            <PagePreviewCard
              page={newsArticle}
              pagePath={`/news/${newsArticle.slug.current}`}
              key={newsArticle._id}
            />
          ))
        ) : (
          <Loader />
        )}
      </article>
    </CenteredContentContainer>
  );
};

export default NewsPage;
