import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';
import PagePreviewCard from '../components/PagePreviewCard';
import SanityPortableText from '../components/SanityPortableText';

import { useCommonInformationContext } from '../contexts/commonInformationContext';

import { StudentInfoPage } from '../types';

import { GetStudentInfoPages } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const StudentInformationListPage = () => {
  const [studentInfoPages, setStudentInfoPages] = useState<Array<StudentInfoPage> | undefined>();
  const informationContext = useCommonInformationContext();

  useEffect(() => {
    GetStudentInfoPages().then((infoPages) => setStudentInfoPages(infoPages));
  }, []);

  return (
    <CenteredContentContainer>
      <Helmet>
        <title>For students - Mars Institute</title>
        <meta property="og:title" content="For students - Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no/students" />

        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <article>
        <h1>For students</h1>
        {informationContext.informationContent?.studentsPageText && (
          <>
            <SanityPortableText content={informationContext.informationContent.studentsPageText} />
            <br />
          </>
        )}
        {studentInfoPages ? (
          studentInfoPages.map((studentInfoPage) => (
            <PagePreviewCard
              page={studentInfoPage}
              pagePath={`/students/${studentInfoPage.slug.current}`}
              key={studentInfoPage._id}
            />
          ))
        ) : (
          <Loader />
        )}
      </article>
    </CenteredContentContainer>
  );
};

export default StudentInformationListPage;
