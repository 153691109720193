// Redirects from old site

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledHiddenLink = styled(Link)`
  display: none;
`;

export const redirectSlugs: { [key: string]: string } = {
  'hmp': '/projects/haughton-mars-project',
  'remains-of-modern-glacier-found': '/news/remains-of-a-modern-glacier-found-near-mars-equator',
  'new-spacesuit-technologies-for-moon-and-mars-exploration-tested-in-oregon':
    '/news/new-spacesuit-technologies-for-moon-and-mars-exploration',
  'astronaut-smart-glove': '/news/an-astronaut-smart-glove-to-explore-the-moon-mars-and-beyond',
  'google-nasa-haughton-mars-partners':
    '/news/google-and-nasa-haughton-mars-project-partner-on-moon-mars-exploration-prep',
  'mars-drone-applications-research':
    '/news/nasa-haughton-mars-project-new-partnership-on-mars-drone-applications-research',
  'human-mars-contamination': '/news/future-human-road-trips-on-mars-unlikely-to-contaminate-planet',
};

// Workaround to hydrate and make redirects work with react-snap
const HiddenRedirectLinks = () => {
  return (
    <>
      {Object.keys(redirectSlugs).map((oldUri) => (
        <StyledHiddenLink to={oldUri} key={oldUri} />
      ))}
    </>
  );
};

export default HiddenRedirectLinks;
