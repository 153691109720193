import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';
import SanityPortableText from '../components/SanityPortableText';

import NotFoundPage from './NotFoundPage';

import { Project } from '../types';

import { GetProject, getImageUrl } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';
import { useParams } from 'react-router-dom';
import { redirectSlugs } from '../components/HiddenRedirectLinks';

const ProjectFaqsPage = () => {
  const { slug } = useParams();
  const [project, setProject] = useState<Project | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      GetProject(redirectSlugs[slug] ?? slug).then((p) => {
        setProject(p);
        setLoading(false);
      });
    }
  }, [slug]);

  return project ? (
    <CenteredContentContainer>
      <Helmet>
        <title>{`${project.title} FAQs - Mars Institute`}</title>
        <meta property="og:title" content={`${project.title} FAQs - Mars Institute`} />
        <meta
          property="og:url"
          content={`https://marsinstitute.no/projects/${project.slug.current}/faqs`}
        />
        <meta name="description" content={`${project.title} FAQs`} />
        <meta property="og:description" content={`${project.title} FAQs`} />
        <meta property="og:image" content={project.logo ? getImageUrl(project.logo) : logo} />
        <meta name="twitter:card" content={'summary'} />
      </Helmet>

      <article>
        <h1>{`${project.title} FAQs`}</h1>
        <SanityPortableText content={project.faqs} />
      </article>
    </CenteredContentContainer>
  ) : loading ? (
    <Loader />
  ) : (
    <NotFoundPage />
  );
};

export default ProjectFaqsPage;
